.notPaid {
    &__title {
        margin-bottom: 150px;
        font-weight: 500;
        font-size: 32px;
        margin-top: 16px;
    }

    &__toPaid {
        display: flex;
        padding-bottom: 15px;
        border-bottom: 1px solid #C4C4C4;
        align-items: flex-end;
        justify-content: space-between;

        p {
            font-size: 21px;
        }

        span {
            display: flex;
            align-items: flex-end;
            font-weight: bold;
            font-size: 60px;
            line-height: 0.8;
            color: var(--main-color);

            sub {
                display: inline-block;
                font-size: 14px;
                font-weight: 500;
            }
        }
    }

    &__tariff {
        padding: 15px 0;
        font-size: 14px;
        color: black;
    }
}