.buttonBack {
    outline-style: none;
    border: none;
    background: transparent;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    margin-left: -10px;
    cursor: pointer;
    img {
        max-width: 15px;
    }
}