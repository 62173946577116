.button {
    outline-style: none;
    border: 2px solid #2D2F30;
    transition: all .3s;
    font-style: normal;
    font-weight: 500;
    font-size: 1.313rem;
    line-height: 1.5rem;
    text-align: center;
    color: white;
    background: #2D2F30;
    border-radius: 50px;
    min-height: 56px;
    max-width: 300px;
    width: 100%;
    margin: 0 auto 15px;
    cursor: pointer;
    position: relative;

    &.loading {
        font-size: 0;

        &:after {
            content: "";
            position: absolute;
            width: 16px;
            height: 16px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            border: 4px solid transparent;
            border-top-color: white;
            border-radius: 50%;
            animation: button-loading-spinner 1s ease infinite;
        }
    }

    &[disabled] {
        opacity: 0.3;
        background: #2D2F30;
        color: white;
        cursor: not-allowed;

        &:active {
            color: white;
        }
    }

    &.inverted,
    &:active {
        color: #2D2F30;
        border-color: #2D2F30;
        background: white;
    }

    &.inverted {
        &:active {
            opacity: 0.3;
        }
    }
}

@keyframes button-loading-spinner {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
}