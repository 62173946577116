.logo {
    background: #5DB5E7;
    transform: rotate(-5deg);
    width: 239.73px;
    height: 67.12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8rem auto;
    @media all and (orientation: landscape) {
        margin: 7rem auto 3rem;
    }
    img {
        transform: rotate(5deg);
    }
}

.main {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    button {
        position: absolute;
        bottom: 40px;
    }
    &:before {
        content: '';
        background-image: url('../../assets/images/bg.jpeg');
        background-size: cover;
        background-position: center;
        position: absolute;
        height: 100vh;
        width: 100vw;
    }
}