.gettingNumber {
    &__number {
        margin-bottom: 8px;
        font-weight: bold;
        font-size: 92px;
        color: var(--main-color);
    }
    &__title {
        margin-bottom: 30px;
        font-size: 21px;
        font-weight: 500;
        color: var(--main-color);
    }
}