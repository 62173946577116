.chooseTariff {
    margin-bottom: 40px;
    &__item {
        label {
            position: relative;
            height: 72px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: 23px;
        }

        p {
            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: #F2F3F9;
                z-index: -1;
                transition: 0.3s;
            }
        }

        & + & {
            margin-top: 20px;
        }

        input:checked ~ p{
            color: white;

            &::before {
                background: var(--main-color);
            }
        }
    }
}