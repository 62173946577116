$black: #333333;
$blue: #007bff;
$gray: #d3d3d3;
$green: #6ab04c;
$red: #dc3545;
$white: #ffffff;


.order {
  &__cell {
    &_pin {
      margin: 25px 0;
      display: flex;
      justify-content: center;
      [completed] {
        input {
          color: var(--success-color);
        }
      }
      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      input {
        margin: 0 5px;
        padding: 0;
        width: 46px;
        height: 80px;
        font-size: 60px;
        font-weight: bold;
        color: var(--main-color);
        text-align: center;
        caret-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid transparent;
        border-radius: 6px;
        &[disabled] {
          opacity: 0.5;
        }
        &::placeholder {
          font-size: 92px;
          font-weight: 700;
          font-weight: bold;
          color: var(--main-color);
        }

        &:focus {
          border-color: rgba(93, 181, 231, 1);
          outline: none;
          -webkit-animation: borderBlink 1s step-end infinite;
          animation: borderBlink 1s step-end infinite;
          border-radius: 4px;
        }

        &:invalid {
          animation: shake 3 linear 75ms;
          border-color: rgb(220, 53, 69);
          box-shadow: 0 0 0.25rem rgba(220, 53, 69, 0.5);
        }
      }
    }
  }
}
.center {
  text-align: center;
}

@keyframes shake {
  from {
    transform: scale(1.05) translateY(-5%);
  }

  to {
    transform: scale(1.05) translateY(5%);
  }
}

@-webkit-keyframes borderBlink {
  from, to {
      border-color: rgba(93, 181, 231, 0);
  }
  50% {
      border-color: rgba(93, 181, 231, .8);
  }
}
@keyframes borderBlink {
  from, to {
      border-color: rgba(93, 181, 231, 0);
  }
  50% {
      border-color: rgba(93, 181, 231, .8);
  }
}