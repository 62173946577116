.header {
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-top: 1px solid #F6F6F6;
    border-bottom: 1px solid #F6F6F6;
    padding: 0 20px;
    .logo {
        background: #5DB5E7;
        transform: rotate(-5deg);
        width: 144px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        img {
            transform: rotate(5deg);
            max-width: 22px;
        }
    }
}