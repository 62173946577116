.lang {
    position: absolute;
    top: 35px;
    right: 35px;
}
.modal {
    width: 300px;
    background: white;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    margin: 7rem auto;
    padding: 20px;
    @media all and (orientation: landscape) {
        margin: 5rem auto;
    }
    &:focus {
        outline-style: none;
    }
    p {
        font-weight: 500;
        font-size: 21px;
        line-height: 24px;
        text-align: center;
        color: #2D2F30;
        margin-bottom: 2rem;
        margin-top: 0;
    }
    button {
        background: none;
        outline-style: none;
        cursor: pointer;
        margin-bottom: 5px;
        border: 1px solid transparent;
        display: flex;
        align-items: center;
        font-weight: normal;
        font-size: 21px;
        line-height: 24px;
        color: #2D2F30;
        height: 60px;
        max-width: 259px;
        padding: 0 40px;
        &.active {
            border: 1px solid #2D2F30;
        }
        img {
            margin-right: 10px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}