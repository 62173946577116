.cells {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4px;
    padding: 10px;
    background: white;
    border: 1px solid #2D2F30;
    margin-bottom: 20px;

    &__greey {
        border-color: #C0C1C1;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 112px;
        background: #2D2F30;

        font-weight: bold;
        font-size: 32px;
        color: white;

        &_main-color {
            background: var(--main-color);
        }

        &_successfully {
            background: #3FC0AA;
        }

        &_error {
            background: #F53A46;
        }

        &_greey {
            background: #C0C1C1;
        }

        &_not-complete {
            background: #F97E3F;
        }
    }
}