.inputWrapper {
    padding-top: 5rem;
    padding-bottom: 8rem;
    @media all and (orientation: landscape) {
        padding-top: 1rem;
        padding-bottom: 2rem;
    }
}
.input {
    display: block;
    height: 68px;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    background: white;
    border: 2px solid #2D2F30;
    font-weight: normal;
    font-size: 25px;
    line-height: 31px;
    color: rgba(0, 0, 0, 0.82);
    padding: 0 1.475rem;
    transition: all .3s;
    @media (max-width: 320px) {
        padding: 0 0.325rem;
    }
}
.label {
    font-weight: 500;
    font-size: 21px;
    line-height: 24px;
    text-align: center;
    color: #2D2F30;
    display: block;
    margin-bottom: 1.5rem;
}
.success {
    border-color: var(--success-color);
    color: var(--success-color);
}
.center {
    text-align: center;
}