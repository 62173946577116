.forbidden {
    margin: 16px 0;
    padding: 20px;
    background: #F2F3F9;

    &__title {
        margin-bottom: 15px;
        font-weight: 500;
        font-size: 18px;
        line-height: 133%;
    }

    &__list {
        margin-bottom: 15px;
        flex-wrap: wrap;
        display: flex;
        & > div {
            margin-bottom: 15px;
            width: 50%;
            div {
                svg {
                    margin-bottom: 5px;
                }
                p {
                    font-size: 14px;
                }
            }
        }
    }

    &__footer {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #C0C1C1;

        font-size: 12px;
        line-height: 1.5;
        color: #000000;

        a {
            color: var(--main-color);
        }
    }

}